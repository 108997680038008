<template>
    <div class="page-forgot-password">
        <LoginMenu />

        <div class="forgot-password-wrapper container">
            <div class="forgot-password-wrapper__body">
                <h2>Zabudnuté heslo</h2>
                <h3>Bude Vám zaslaný email pre obnovenie hesla.</h3>

                <b-form @submit.prevent="postEmail()">
                    <b-form-group label="Email*" label-for="email_input">
                        <b-form-input id="email_input" v-model="email"></b-form-input>
                        <div class="error-message-form text-center" v-show="$v.email.$error">
                            <p v-show="!$v.email.required || !$v.email.email">Zadajte Váš email.</p>
                        </div>
                    </b-form-group>

                    <b-button type="submit" variant="primary-lighter" class="send-button">
                        <BeatLoader v-if="spinners.post_data" />
                        <span v-else>ODOSLAŤ</span>
                    </b-button>

                    <ResponseSuccess v-if="response_type.success" :success="response_type.success" />
                    <ResponseError v-if="response_type.failed" :errors="response_type.failed" />
                </b-form>
            </div>
            <img :src="asset_1" class="asset_1" alt="image" />
        </div>
    </div>
</template>

<script>
import config from "@/config";

import forgot_password_image from "@/assets/login/forgot_password_image.svg";
import LoginMenu from "@/components/login/LoginMenu";
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';
import ResponseError from "@/components/ResponseError";
import ResponseSuccess from "@/components/ResponseSuccess";

import { required, email } from 'vuelidate/lib/validators';

export default {
    name: "PageForgotPassword",
    metaInfo() {
        return {
            title: 'Obnova hesla | ',
        }
    },
    components: {
        ResponseSuccess,
        ResponseError,
        LoginMenu,
        BeatLoader,
    },
    data() {
        return {
            asset_1: forgot_password_image,

            email: '',

            spinners: {
                post_data: false,
            },
            response_type: {
                success: '',
                failed: null,
            },
        }
    },
    methods: {
        postEmail() {
            this.$v.$touch()
            if (this.$v.$invalid) return false
            this.postLoginUser()
        },
        postLoginUser() {
            this.resetResponseType()
            if (this.spinners.post_data) return false
            this.spinners.post_data = true

            const requestData = new FormData();
            requestData.set('email', this.email);

            this.axios.post(config.API_URL + '/auth/reset-password', requestData, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.response_type.success = responseData.data.message
                    } else {
                        this.response_type.failed = responseData.errors
                    }
                    this.spinners.post_data = false
                }, (error) => {
                    console.warn(error)
                    this.spinners.post_data = false
                });
        },
        resetResponseType() {
            this.response_type.success = ''
            this.response_type.failed = null
        },
    },
    validations: {
        email: {
            required,
            email,
        }
    }
}
</script>

<style lang="scss" scoped>
.page-forgot-password::v-deep {
    background-color: $body-gray;
    min-height: 100vh;
    position: relative;

    .forgot-password-wrapper {
        @extend %flex-column-center;
        justify-content: center;
        position: relative;
        padding-top: em(100);

        &__body {
            position: relative;
            z-index: 1;

            h2 {
                margin-bottom: em(10);
            }

            .form-group {
                margin-top: em(100);
            }

            .send-button {
                min-width: em(120, 16);
            }
        }

        .asset_1 {
            position: absolute;
            width: 100%;
            max-width: em(800);
            top: em(50);
        }
    }

    @media (max-width: 991px) {
        .forgot-password-wrapper {
            padding-top: em(50);

            &__body {
                .form-group {
                    margin-top: em(50);
                }
            }

            .asset_1 {
                display: none;
            }
        }
    }
}
</style>